import { website } from '@/axios'
export const areasLink = 'admin/areas'

export default {
  getDetails (id) {
    return website().get(`${areasLink}/${id}`)
  },
  add (payload) {
    return website().post(areasLink, payload)
  },
  update (id, payload) {
    return website().patch(`${areasLink}/${id}`, payload)
  }
}
