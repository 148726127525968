<template>
  <div>
    <dashboard-page-title :showAddBtn="false">{{ pageTitle }}</dashboard-page-title>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <b-row>
          <b-col md="12" class="mb-3">
            <main-select
                :reduce="i => i.id"
                :options="cities"
                class="bg-select"
                :labelTitle="$t('cities.city')"
                :placeholder="$t('cities.city')"
                v-model="item.city_id"
                label="name"
                :disabled="disabled"
              />
          </b-col>
          <b-col md="12" class="mb-3">
            <input-form placeholder="الاسم العربي" label="الاسم العربي" :name="$t('main.name')" validate="required" v-model="item.name_ar" :disabled="disabled"/>
          </b-col>
          <b-col md="12" class="mb-3">
            <input-form placeholder="الاسم الانجليزي" label="الاسم الانجليزي" :name="$t('main.name')" validate="required" v-model="item.name_en" :disabled="disabled"/>
          </b-col>

          <b-col cols="12" v-if="!disabled">
            <b-button variant="primary" type="submit" :disabled="loadingSubmit">
              <span v-if="!loadingSubmit">{{ $t('main.save') }}</span>
              <template v-else>
                <spinner-loading class="d-inline"></spinner-loading>
                <span>{{ $t('main.loading') }}</span>
              </template>
            </b-button>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import globalAdd from '@/mixins/globalAdd'
import areasServices from '../services/areas'

import citiesServices from '../../cities/services/cities'
export default {
  mixins: [globalAdd],
  mounted () {
    core.index()
  },
  props: { id: { type: [String, Number], default: '' } },
  data () {
    return {
      pageName: this.$route?.meta?.name || '',
      loadingSubmit: true,
      progressImage: 0,
      item: {
        name_ar: '',
        name_en: '',
        city: null
      },
      cities: []
    }
  },
  methods: {
    getDetails () {
      areasServices.getDetails(this.id).then(response => {
        const data = response?.data?.data
        this.item = {
          name_ar: data?.name_ar || '',
          name_en: data?.name_en || '',
          city_id: data?.city?.id || ''
        }
        this.loadingSubmit = false
      })
    },
    getAllcities () {
      citiesServices.getAll().then((res) => { this.cities = res?.data?.data || [] })
    },
    saveImage (file) {
      const formData = new FormData()
      formData.append('file', file.image)
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressImage = percent
        }
      }
      this.commonUploadFiles(formData, options).then(res => {
        this.item.flag = res.data
        this.showSuccessUploadFile()
      })
    },
    onSubmit () {
      this.loadingSubmit = true
      if (this.id) {
        areasServices.update(this.id, this.item).then(res => {
          core.showSnackbar('success', this.$t('main.updatedSuccessfully'))
          this.loadingSubmit = false
          this.$router.push({ name: 'areas' })
        }).catch(() => {
          this.loadingSubmit = false
        })
      } else {
        areasServices.add(this.item).then(res => {
          core.showSnackbar('success', this.$t('main.addedSuccessfully'))
          this.loadingSubmit = false
          this.$router.push({ name: 'areas' })
        }).catch(() => {
          this.loadingSubmit = false
        })
      }
    }
  },
  computed: {
    pageTitle () {
      switch (this.pageName) {
        case 'areas.add':
          return this.$t('areas.add')
        case 'areas.update':
          return this.$t('areas.update')
        default:
          return this.$t('areas.details')
      }
    },
    disabled () {
      switch (this.pageName) {
        case 'areas.add':
        case 'areas.update':
          return false
        default:
          return true
      }
    }
  },
  created () {
    if (this.id) {
      console.log('this.id', this.id)
      this.getDetails()
    } else {
      this.loadingSubmit = false
    }
    this.getAllcities()
  }
}
</script>
